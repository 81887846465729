import { getLocalTimeZone, parseDate, today } from '@internationalized/date'
import { isString } from '@yescapa-dev/ysc-api-js/modern'
import type { APISearchLocationItem } from '@yescapa-dev/ysc-api-js/modern'

export const useStoredLastQueries = () => {
  type LastQuery = { locationId: string, label: string, countryCode: string, address: APISearchLocationItem['address'], date_from?: string, date_to?: string }

  const isSavedQuery = (v: any): v is LastQuery => {
    if (typeof v !== 'object') return false

    const { locationId, label, countryCode } = v ?? {}

    return isString(locationId) && isString(label) && isString(countryCode)
  }

  const isUnique = (x: LastQuery, i: number, arr: LastQuery[]) => arr.findIndex(y => x.locationId === y.locationId) === i

  // remove date_from & date_to if outdated
  const formatOutdatedDate = (query: LastQuery) => {
    if (!query.date_from || !query.date_to || parseDate(query.date_from).compare(today(getLocalTimeZone())) >= 0) {
      return query
    }
    delete query.date_from
    delete query.date_to
    return query
  }

  // returns a sanitized array of max five unique values
  const sanitizeStoredLastQueries = (unclean: any): LastQuery[] => {
    if (!Array.isArray(unclean)) {
      return []
    }

    const MAX_QUERIES_TO_DISPLAY = 5
    return unclean.reduce((acc, query, i) => {
      if (
        acc.length < MAX_QUERIES_TO_DISPLAY
        && isSavedQuery(query)
        && isUnique(query, i, unclean)
      ) {
        acc.push(formatOutdatedDate(query))
      }
      return acc
    }, [])
  }

  const storedLastQueries = useLocalStorage<LastQuery[]>(
    'ysc-search-last-queries-20241203', // Key was updated following breaking change.
    [],
    {
      mergeDefaults: true,
      serializer: {
        read: (v: any) => {
          try {
            return sanitizeStoredLastQueries(JSON.parse(v))
          }
          catch (_e) {
            return []
          }
        },
        write: (v: any) => JSON.stringify(v),
      },
    },
  )

  return { storedLastQueries, sanitizeStoredLastQueries }
}
